.kromac-gallery {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}

.kromac-gallery-polygon {
  margin-bottom: 1em;
  max-height: calc((var(--totalRows) / 2) * 450px);

  .bgblur {
    backdrop-filter: blur(5px) brightness(0.2);
    height: 100%;
    left: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: 0;

    &.active {
      width: 100%;
      z-index: 1;
    }
  }

  .kromac-col {
    display: flex;
    justify-content: center;
    padding: 15px;
    pointer-events: none;
    top: calc(var(--rowNumber) * -150px);

    &.rhombus {
      position: relative;
      top: calc(var(--rowNumber) * -150px);

      .kromac-gallery {
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      }
    }

    &.hexagon {
      position: relative;
      top: calc(var(--rowNumber) * -100px);

      .kromac-gallery {
        clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
      }
    }

    &.rabbet {
      position: relative;
      top: calc(var(--rowNumber) * -77px);

      .kromac-gallery {
        clip-path: polygon(
          0% 15%,
          15% 15%,
          15% 0%,
          85% 0%,
          85% 15%,
          100% 15%,
          100% 85%,
          85% 85%,
          85% 100%,
          15% 100%,
          15% 85%,
          0% 85%
        );
      }
    }

    &.active {
      height: 90%;
      left: 50%;
      margin: 0;
      max-width: 90%;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      z-index: 1001;

      .kromac-gallery.active {
        clip-path: none;
        position: initial;
        z-index: 1001;

        .brillo {
          width: 0;
        }

        &:hover > .kromac-gallery-image img {
          transform: scale(1);
        }

        .kromac-gallery-image {
          img {
            object-fit: contain;
            object-position: center center !important;
            transform: scale(1);
          }
        }
      }

      .close {
        align-items: center;
        display: flex;
        height: 50px;
        justify-content: center;
        width: 50px;
      }
    }

    .close {
      background: #000;
      border: none;
      border-radius: 50%;
      box-shadow: none;
      color: #fff;
      display: none;
      font-size: 3em;
      pointer-events: visible;
      position: absolute;
      right: 5px;
      z-index: 10000000;

      img {
        height: 50px;
        width: 50px;
      }
    }

    .kromac-gallery {
      height: 300px;
      margin: 1px;
      width: 300px;

      p {
        left: 50%;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      &:hover > .brillo {
        animation: brillo 1s ease-out;
        z-index: 1;
      }

      &:hover > .kromac-gallery-image img {
        transform: scale(1.2);
        transition: all 1s ease;
      }

      .kromac-gallery-image {
        height: 100%;
        pointer-events: visible;
        position: absolute;
        width: 100%;

        img {
          cursor: pointer;
          height: 100%;
          object-fit: cover;
          transition: all 1s ease;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .kromac-gallery-polygon {
    .kromac-row {
      .kromac-col {
        &.rhombus {
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .kromac-gallery-polygon {
    max-height: fit-content;
    .kromac-row {
      .kromac-col {
        padding-bottom: 1em;
        top: 0;

        &.active,
        &.last {
          top: 50%;
        }
        .close {
          top: 50px;
        }
      }
    }
  }
}
