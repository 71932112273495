.kromac-toast {
  position: fixed;
  transition: all 0.5s ease;
  width: 350px;
  z-index: 1000000 !important;

  .kromac-toast-content {
    align-items: center;
    border-radius: 5px;
    bottom: -15px;
    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 1em;
    transition: 0.5s opacity ease;
    width: 100%;

    p {
      margin: 0;
      padding-left: 5px;
      width: 100%;
    }

    span {
      align-items: center;
      color: #fff;
      cursor: pointer;
      display: flex;
      font-size: 1.5em;
      justify-content: center;
      max-height: 25px;
      max-width: 25px;
      min-height: 25px;
      min-width: 25px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &.transparent {
      backdrop-filter: blur(20px);

      span {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        color: #fff;
      }
    }
  }
}

@media (max-width: 540px) {
  .kromac-toast {
    left: 5%;
    right: 5%;
    width: 90% !important;
  }
}
