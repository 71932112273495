.kromac-skeleton {
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-align: center;

  &.dark {
    background: #212f3d;
  }

  &.light {
    background: #eaeded;
  }

  .kromac-skeletonLoader {
    animation: skeleton 3s infinite ease;
    content: "";
    filter: blur(10px);
    height: 100%;
    position: absolute;
    width: 40%;

    &.dark {
      background: linear-gradient(90deg, #212f3d 0%, #aab7b8 50%, #212f3d 100%);
    }

    &.light {
      background: linear-gradient(90deg, #eaeded 0%, #aab7b8 50%, #eaeded 100%);
    }

    @keyframes skeleton {
      0% {
        left: -40%;
      }
      100% {
        left: 100%;
      }
    }
  }
}
