.kromac-banner-grid {
  display: grid;
  grid-column: 2;
  grid-template-columns: 50% 50%;
  overflow-x: hidden;
  width: 100%;
}

.order-image-first {
  grid-row-start: 1;
}
