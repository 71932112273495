.kromac-slider {
  background: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  width: 100%;

  .separators {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: absolute;
    width: 100%;

    span {
      align-items: center;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      display: flex;
      font-size: 3em;
      justify-content: center;
      z-index: 100;

      img {
        color: rgb(204, 204, 204);
        filter: contrast(0);
        padding: 0 5px;
        pointer-events: all;
      }
    }
  }

  .kromac-slider-preview {
    bottom: 5px;
    display: flex;
    height: 50px;
    justify-content: center;
    pointer-events: all;
    position: absolute;
    width: 100%;
    z-index: 1;

    .kromac-spinner {
      position: absolute;
      z-index: 11;
    }

    .kromac-slide-preview {
      border-radius: 0.25em;
      cursor: pointer;
      height: 100%;
      margin: 0 5px;
      max-width: 75px;
      min-width: 75px;
      transition: 1s ease;
      width: auto;

      img {
        border: solid #fff 1px;
        border-radius: 0.25em;
        box-shadow: 0 0 0px 1px #000;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &:hover {
        transform: scale(1.5) translateY(-15px);
        transition: 1s ease;
      }
    }
  }

  .kromac-slider-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    left: calc(var(--left) * -100%);
    position: relative;
    text-align: center;
    transition: 1s ease;
    width: 100%;

    .kromac-slide {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      position: relative;
      width: 100%;

      p {
        color: rgb(255, 255, 255);
        position: absolute;
        text-align: center;
        width: 100%;
        z-index: -1;
      }

      .kromac-skeleton {
        position: absolute;
      }

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }

      .kromac-slider-content {
        align-content: cener;
        align-items: flex-end;
        background: rgba(0, 0, 0, 0.25);
        bottom: 0;
        color: #fff;
        display: flex;
        justify-content: flex-start;
        max-height: 100%;
        padding: 1em;
        position: absolute;
        text-align-last: left;
        width: 100%;
        z-index: 1;

        p {
          display: block;
          position: relative;
        }
      }
    }

    .bgblur {
      backdrop-filter: blur(5px) brightness(0.2);
      height: 100%;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 0;
    }
  }
}

@media (max-width: 650px) {
  .kromac-slider {
    .kromac-slider-preview {
      display: none;
    }
  }
}
