.kromac-container.card-img {
  .kromac-card {
    border-radius: 1em !important;
  }

  .card-text {
    display: none;
  }

  .card-title {
    border-radius: 0 0 1em 1em;

    h4 {
      max-width: 100% !important;
    }

    label {
      display: none;
    }
  }

  .kromac-card-image {
    border-radius: 1em !important;

    img {
      border-radius: 1em !important;
    }
  }
}
