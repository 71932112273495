.kromac-container.reveal {
  margin: 15px 0;

  .kromac-card {
    box-shadow: none;

    .kromac-card-image {
      align-items: center;
      background: transparent;
      display: flex;
      height: 300px;
      justify-content: center;
      width: 100%;
      z-index: 100;

      .kromac-skeleton {
        position: absolute;
      }

      img,
      video,
      iframe {
        border-radius: 0.5em;
        min-height: 300px;
        width: 80%;
      }
    }

    .kromac-card-caption {
      background: #fff;
      border-radius: 0.5em;
      box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
      color: transparent;
      margin-top: -65%;
      max-height: 260px;
      overflow: hidden;
      padding: 10%;
      padding-bottom: 0.5em;
      transition: all var(--transition) ease;
      width: 100%;

      .card-title {
        color: transparent;
        margin-top: 75%;
        transition: all var(--transition) ease;

        h4 {
          margin-bottom: 0;
          text-align: center;
        }
      }
    }

    &:hover > .kromac-card-caption {
      color: #000;
      max-height: 1000px;

      .card-title {
        color: #000;
      }

      &.transparent {
        color: #fff;
        text-shadow: 1px 1px 2px black, 0 0 0.25em white, 0 0 0.2em black;

        .card-title {
          color: #fff;
        }
      }
    }
  }
}
