.kromac-spinner.spinner-svg {
  &::before {
    background: var(--bgSpiner);
    border-radius: 50%;
    content: "";
    height: 100px;
    left: 50px;
    position: absolute;
    top: 50px;
    width: 100px;
  }

  .loader {
    span {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: rotate(calc(18deg * var(--i)));
      width: 100%;

      &::before {
        animation: spinnerSVG 2s infinite linear;
        animation-delay: calc(0.1s * var(--i) * -1);
        background: var(--loaderColor);
        border-radius: 50%;
        content: "";
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
        width: 15px;
      }
    }

    .rocket {
      animation: svgRotate 2s infinite linear;
      animation-delay: -1s;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      img {
        color: var(--loaderColor);
        filter: contrast(0);
        font-size: 50px;
        height: 50px;
        left: 95px;
        position: absolute;
        top: 80px;
        width: 50px;
      }
    }
  }
}

@keyframes spinnerSVG {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes svgRotate {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
