$textShadow-bg-white: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;

.kromac-container.card-hexagon {
  .kromac-card {
    height: 350px;
    margin: 10px 0;
    max-width: 300px;
    min-width: 300px;
    width: 300px;

    .kromac-card-image {
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }

    .kromac-card-caption {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      opacity: 0;
      padding: 20px;
      position: absolute;
      top: 0;
      transition: 1s;
      width: 100%;

      div {
        text-align: center;
      }
    }

    &:hover > .kromac-card-caption {
      opacity: 1;
    }

    &.hexagon {
      clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    }

    &.rhombus {
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }

    &.pentagon {
      clip-path: polygon(50% 0%, 100% 45%, 82% 100%, 18% 100%, 0 45%);
    }

    &.heptagon {
      clip-path: polygon(
        50% 0%,
        90% 20%,
        100% 60%,
        75% 100%,
        25% 100%,
        0% 60%,
        10% 20%
      );
    }

    &.octagon {
      clip-path: polygon(
        30% 0%,
        70% 0%,
        100% 30%,
        100% 70%,
        70% 100%,
        30% 100%,
        0% 70%,
        0% 30%
      );
    }

    &.bevel {
      clip-path: polygon(
        20% 0%,
        80% 0%,
        100% 20%,
        100% 80%,
        80% 100%,
        20% 100%,
        0% 80%,
        0% 20%
      );
    }

    &.circle {
      clip-path: circle(50% at 50% 50%);
    }
  }
}
