.kromac-banner-text {
  background-size: cover;
  margin-bottom: 3em;
  position: relative;

  .kromac-banner-caption,
  .kromac-banner-image {
    align-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .kromac-banner-container {
    height: 100%;

    .kromac-banner-grid {
      height: 100%;
      margin-bottom: 0;
      overflow: hidden;
    }
  }

  .kromac-banner-image {
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .kromac-banner-caption {
    mix-blend-mode: screen;
    overflow: hidden;
    padding: 1em;
    position: relative;
    width: auto;
    z-index: 1000;

    div {
      h1 {
        color: #000;
        display: contents;
        font-size: 5em;
        font-weight: 800;
        mix-blend-mode: screen;
        text-transform: uppercase;
      }

      p {
        color: #010101;
      }
    }
  }

  &.normal {
    .kromac-banner-container {
      .kromac-banner-image {
        img {
          max-height: var(--heightImg);
          width: var(--widthImg);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .kromac-banner-text {
    .kromac-banner-container {
      .kromac-banner-grid {
        grid-template-columns: 100%;
      }
    }
  }
}
