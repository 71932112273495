$bg-kromac: linear-gradient(90deg, #17202a 0%, #fff 100%);

$txt-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;

.kromac-components {
  background: $bg-kromac;
  height: 100vh;
  overflow: auto;

  .kromac-title {
    background: $bg-kromac;

    a {
      align-items: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      text-shadow: $txt-shadow;
      z-index: 1;

      img {
        height: 100px;
        width: 100px;
      }

      p {
        margin-bottom: 5px;
      }
    }

    .kromac-version-select {
      margin: auto;
      margin-bottom: 10px;
    }

    .kromac-input-search {
      input,
      input:focus,
      input:target,
      input:active {
        background: #fff;
        border: none;
        border-radius: 0.25em;
        box-shadow: 0px 0px 15px -5px;
        height: 24px;
        outline: none;
        padding: 5px 10px;
        width: 80%;
      }
    }
  }

  .kromac-components-list {
    position: relative;

    ul {
      margin: 0;
      padding-top: 10px;

      li {
        border-bottom: solid 1px #fff;
        border-left: 0;
        border-right: 0;
        margin-bottom: 2px;
        padding: 0.5em;

        .sub-components {
          max-height: 0;
          opacity: 0;
          padding: 0;
          transition: 1s all ease;

          &.active {
            max-height: 500px;
            opacity: 1;
          }

          li {
            &:nth-last-child(1) {
              border: none;
            }
          }
        }

        a {
          color: #fff;
          text-shadow: $txt-shadow;
        }
      }
    }
  }
}
