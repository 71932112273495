.kromac-toggle.toggle-power {
  height: auto;
  padding-left: 1em;
  padding-top: 0.5em;
  width: auto;

  .kromac-checkbox {
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    position: relative;
    width: 40px;

    .span-toggle {
      background-color: #f00;
      border-radius: 50%;
      box-shadow: 0 3px 4px rgba(0, 0, 0, 1), inset 0 -2px 5px rgba(0, 0, 0, 1),
        inset 0 2px 5px rgba(255, 255, 255, 0.5), 0 0 0 2px #000,
        0 0 0 5px #0c0c0c, 0 0 0 6px #f00;
      display: block;
      height: 100%;
      left: 0;
      min-height: 40px;
      min-width: 40px;
      position: absolute;
      top: 0;
      width: 100%;

      img {
        height: 100%;
        left: 0;
        min-height: 40px;
        min-width: 40px;
        position: absolute;
        width: 100%;
      }

      &::before,
      &::after {
        display: none;
      }
    }

    input[type="checkbox"] {
      -webkit-appearance: none;

      &:checked ~ .span-toggle img {
        background-color: #00a1ff;
        border-radius: 50%;
        color: #00ffe7;
        text-shadow: 0 0 15px #00a1ff, 0 0 30px #00a1ff;
      }

      &:checked ~ .span-toggle {
        box-shadow: 0 3px 4px rgba(0, 0, 0, 1),
          inset 0 -2px 5px rgba(0, 0, 0, 1),
          inset 0 2px 5px rgba(255, 255, 255, 0.5), 0 0 0 2px #000,
          0 0 0 5px #0c0c0c, 0 0 0 6px #00ffe7;
      }
    }
  }
}
