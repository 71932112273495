.kromac-grid {
  display: grid;
  grid-auto-flow: dense;
  position: relative;
  width: 100%;

  & * {
    position: relative;
    width: 100%;
  }
}
