.li-container {
  display: flex;
  padding: 0.5em;

  .div-components {
    border: solid 1px;
    margin: auto 0.5em;
    padding: 0.5em 2em;
    position: relative;

    &::before {
      background-color: #000;
      content: "";
      height: 4px;
      position: absolute;
      right: -9px;
      top: 50%;
      width: 5px;
    }

    &::after {
      border-bottom: solid 5px transparent;
      border-left: solid 5px #000;
      border-top: solid 5px transparent;
      content: "";
      position: absolute;
      right: -13px;
      top: calc(50% - 3px);
    }

    h5 {
      border-bottom: solid 1px;
    }
  }
  div {
    h5 {
      text-align: center;
    }

    pre {
      border: solid 1px;
      height: max;
      margin: auto 0.5em;
      padding: 0.5em 2em;
    }
  }
}

.release-notes {
  font-weight: 400;

  ul {
    margin-left: 1em;

    li {
      list-style: initial;
      margin-left: 1em;
    }
  }
}

@media (max-width: 540px) {
  .li-container {
    display: block;

    .div-components {
      margin-bottom: 1em;
      padding: 0.5em;
      position: relative;

      &::before {
        background-color: #000;
        content: "";
        height: 10px;
        left: 50%;
        position: absolute;
        top: calc(100% + 5px);
        width: 4px;
      }

      &::after {
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;
        border-top: solid 5px #000;
        content: "";
        left: calc(50% - 3px);
        position: absolute;
        top: calc(100% + 15px);
        width: 4px;
      }

      h5 {
        font-size: 1em !important;
      }
    }
  }
}
