.kromac-avatar-container {
  margin-bottom: 1em;
  position: relative;

  &:hover > .kromac-tooltip {
    opacity: 1;
    top: -30px;
  }

  .kromac-tooltip {
    background: #000;
    color: #fff;
    font-size: 0.75em;
    left: calc(var(--size) / 2 - 15px);
    opacity: 0;
    padding: 0.5em;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: 1s ease all;
    width: max-content;
    z-index: 100;

    &::before {
      border-left: solid 5px transparent;
      border-right: solid 5px transparent;
      border-top: solid 5px #000;
      bottom: -10px;
      content: "";
      height: 10px;
      left: 7px;
      position: absolute;
      width: 10px;
    }
  }

  .kromac-avatar {
    align-content: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;

    .kromac-avatar-section {
      align-items: center;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      position: relative;
      z-index: 2;

      .kromac-skeleton {
        z-index: 1;
      }

      img,
      video,
      iframe {
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: auto;
      }
    }

    &:hover > .kromac-avatar-name {
      transform: var(--transformHover);
      transition: var(--transition) transform ease;
    }

    .kromac-avatar-name {
      align-items: center;
      border-radius: 0 75px 75px 0;
      color: #fff;
      display: flex;
      padding-left: calc(var(--paddingLeft) + 1em);
      padding-right: 2em;
      transform: var(--transform);
      transition: var(--transition) transform ease;
      z-index: -1;
      z-index: 1;

      h3 {
        margin: 0;
      }
    }
  }
}
