$textShadow-bg-white: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;

.kromac-container.classic,
.kromac-container.card-img {
  .kromac-card {
    border-radius: 0.25em;
    margin: 10px 0;
    position: relative;

    .kromac-card-image {
      height: 200px;
      width: 100%;

      img,
      video,
      iframe {
        border-radius: 0.25em 0.25em 0 0;
      }

      .card-title {
        width: -webkit-fill-available !important;

        h4 {
          text-shadow: $textShadow-bg-white;
        }
      }
    }

    .kromac-card-caption {
      background: #fff;
      border-radius: 0 0 0.25em 0.25em;

      .card-text {
        max-height: 150px;

        p {
          margin-bottom: 0;
        }

        &.sm {
          margin-bottom: 0;
          max-height: 55px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          p {
            display: contents;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &.lg {
          max-height: max-content;
        }
      }

      &.transparent {
        border-radius: 0 0 1em 1em;
        text-shadow: $textShadow-bg-white;
      }
    }
  }
}
