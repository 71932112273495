$textShadow-bg-white: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;

.kromac-container.card-info {
  .kromac-card {
    border-radius: 0.25em;
    margin: 10px 0;
    position: relative;

    span {
      background: rgba(0, 0, 0, 0.3);
      bottom: 6px;
      left: 6px;
      position: absolute;
      right: 6px;
      top: 6px;

      &::before {
        background: rgba(255, 255, 255, 0.1);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;
      }
    }

    .kromac-card-caption {
      height: auto;
      overflow: hidden;
      padding: 1em 2em;
      position: relative;

      .card-title {
        margin-top: 3em;
      }

      .card-text {
        margin-bottom: 3em;
        max-height: 250px;
        transition: max-height 0.5s ease-out;
        color: #fff;

        p {
          margin-bottom: 1em;
        }

        button {
          display: inline-block;
        }

        &.sm {
          max-height: 150px;
        }

        &.lg {
          max-height: max-content;
        }
      }
    }

    &.transparent {
      span,
      .kromac-card-caption {
        border-radius: 1em;

        &::before {
          border-radius: 0.75em 0 0 0.75em;
        }
      }
    }
  }
}
