.kromac-text-animation {
  position: relative;
  width: auto;

  div {
    animation: hideText 6s infinite;
    width: auto;

    h2 {
      color: transparent;
      font-family: monospace;
      position: absolute;
      text-transform: uppercase;
      width: auto;

      &::after {
        background: var(--bg);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &::before {
        animation: textAnimation var(--timer) infinite steps(var(--steps));
        color: var(--fontColor);
        content: attr(data-text);
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 0;
        z-index: 100;
      }

      &:nth-child(1)::before {
        animation-delay: calc(var(--timer) * -1);
      }

      &:nth-child(1)::after {
        z-index: 10;
      }

      &:nth-child(2)::before {
        animation-delay: calc(var(--timer) / 2);
      }
    }
  }
}

@keyframes textAnimation {
  0%,
  20%,
  100% {
    width: 0;
  }

  50%,
  60% {
    width: 100%;
  }

  70%,
  90% {
    opacity: 1;
    width: 0%;
  }
}
