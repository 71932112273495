$textShadow: 1px 1px 2px #000, 0 0 0.25em #000, 0 0 0.2em #000;

.kromac-btn,
.kromac-btn:active,
.kromac-btn:hover,
.kromac-btn:focus {
  align-items: center;
  backdrop-filter: blur(20px);
  box-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 0.5);
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  margin: 0.5em 0;
  max-height: 75px;
  min-height: 50px;
  min-width: 150px;
  min-width: 165px;
  overflow: hidden;
  padding: 0;
  position: relative;

  &.disabled,
  &.disabled:hover {
    cursor: not-allowed;
  }

  .button-content {
    padding: 1em 3em;

    &.spinner {
      display: contents;

      p {
        margin-left: 25px;
      }

      .kromac-spinner {
        left: -70px;
        position: absolute;
        transform: scale(0.15);

        span {
          height: auto;
          width: auto;
        }
      }
    }
  }

  p {
    color: #fff;
    margin: 0;
    position: relative;
    z-index: 100;

    a {
      color: #fff;
    }
  }

  .kromac-span-button {
    content: "";
    height: 50%;
    left: 0;
    position: absolute;
    top: 50%;
    transition: background 1s ease;
    width: 100%;
  }

  &:hover {
    border: solid 1px #fff;
    transition: background 1s ease;

    .kromac-span-button {
      background: rgba(0, 0, 0, 0.5);
      content: "";
      height: 50%;
      left: 0;
      position: absolute;
      top: 50%;
      transition: background 1s ease;
      width: 100%;
    }
  }

  &.primary {
    background-color: #2e86c1;
    border: solid 1px #2e86c1;

    p {
      text-shadow: 1px 1px 2px #2e86c1, 0 0 0.25em #2e86c1, 0 0 0.2em #2e86c1;
    }

    .kromac-span-button {
      background: #2e86c1;
    }

    &:hover {
      background: #2e86c1;

      .kromac-span-button {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &.success {
    background-color: #28b463;
    border: solid 1px #28b463;

    p {
      text-shadow: 1px 1px 2px #28b463, 0 0 0.25em #28b463, 0 0 0.2em #28b463;
    }

    .kromac-span-button {
      background: #28b463;
    }

    &:hover {
      background: #28b463;
      .kromac-span-button {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &.warning {
    background-color: #f1c40f;
    border: solid 1px #f1c40f;

    p {
      text-shadow: 1px 1px 2px #f1c40f, 0 0 0.25em #f1c40f, 0 0 0.2em #f1c40f;
    }

    .kromac-span-button {
      background: #f1c40f;
    }

    &:hover {
      background: #f1c40f;
      .kromac-span-button {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &.error {
    background-color: #cb4335;
    border: solid 1px #cb4335;

    p {
      text-shadow: 1px 1px 2px #cb4335, 0 0 0.25em #cb4335, 0 0 0.2em #cb4335;
    }

    .kromac-span-button {
      background: #cb4335;
    }

    &:hover {
      background: #cb4335;
      .kromac-span-button {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &.danger {
    background-color: #d68910;
    border: solid 1px #d68910;

    p {
      text-shadow: 1px 1px 2px #d68910, 0 0 0.25em #d68910, 0 0 0.2em #d68910;
    }

    .kromac-span-button {
      background: #d68910;
    }

    &:hover {
      background: #d68910;
      .kromac-span-button {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &.info {
    background-color: #d5dbdb;
    border: solid 1px #d5dbdb;

    p {
      text-shadow: 1px 1px 2px #000, 0 0 0.25em #000, 0 0 0.2em #000;
      transition: all 1s ease;
    }

    .kromac-span-button {
      background: #d5dbdb;
    }

    &:hover {
      background: #d5dbdb;
      .kromac-span-button {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &.dark {
    background-color: #212f3d;
    border: solid 1px #212f3d;

    p {
      text-shadow: 1px 1px 2px #212f3d, 0 0 0.25em #212f3d, 0 0 0.2em #212f3d;
    }

    .kromac-span-button {
      background: #212f3d;
    }

    &:hover {
      background: #212f3d;
      .kromac-span-button {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &.transparent {
    background-color: transparent;
    border: solid 1px #fff;

    p {
      color: #fff;
      text-shadow: 1px 1px 2px #212f3d, 0 0 0.25em #212f3d, 0 0 0.2em #212f3d;
    }

    .kromac-span-button {
      display: none;
    }
  }

  &.neon {
    border: none;
    position: relative;

    p {
      text-shadow: none !important;
    }

    .kromac-span-button {
      background: #212f3c;
      bottom: 4px;
      content: "";
      display: block;
      height: auto;
      left: 4px;
      position: absolute;
      right: 4px;
      top: 4px;
      transition: background-position 1s ease;
      width: auto;
    }

    &::before {
      animation: btn-neon-animate 20s linear infinite;
      background: linear-gradient(
        45deg,
        #fb0094,
        #00f,
        #0f0,
        #ff0,
        #f00,
        #0f0,
        #ff0,
        #f00
      );
      background-size: 400%;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 1s ease;
      width: 100%;
    }

    &.primary,
    &.success,
    &.warning,
    &.error,
    &.danger,
    &.info,
    &.dark {
      &:hover {
        .kromac-span-button {
          background: #212f3c;
        }
      }
    }
  }
}

@keyframes btn-neon-animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 300% 0;
  }
  100% {
    background-position: 0 0;
  }
}
