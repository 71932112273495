.kromac-spinner.spinner-waves {
  .loader {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: rotate(175deg);

    span {
      position: absolute;
      top: calc(12px * (var(--i) - 1));
      left: calc(12px * (var(--i) - 1));
      right: calc(12px * (var(--i) - 1));
      bottom: calc(12px * (var(--i) - 1));
      border: solid 10px #0b0b0b;
      border-top: solid 10px #0f0;
      border-left: solid 10px #0f0;
      border-radius: 50%;
      animation: spinnerWaves 1s alternate ease-in-out infinite;
      filter: hue-rotate(calc(60deg * var(--i)));
      animation-delay: calc(-0.1s * var(--i));
    }
  }
}
