.brillo {
  background: linear-gradient(90deg, transparent, #ffffff80, transparent);
  cursor: pointer;
  height: 100%;
  left: -100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: -1;
}

@keyframes brillo {
  0%,
  10% {
    left: -100%;
  }
  50%,
  60% {
    left: 100%;
  }
}
