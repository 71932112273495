.landingpage {
  .kromac-title-landingpage {
    padding: 2em;
    text-align: center;

    img {
      height: 100px;
      width: 100px;
    }
  }

  .kromac-landingpage-container {
    .kromac-section {
      border-bottom: solid 1px #566573;
      margin-bottom: 2em;
      padding: 1em;
      position: relative;

      &:nth-last-child(1) {
        border: none;
      }

      .div-implementation {
        margin: 1em 0;
      }

      .kromac-text-animation {
        height: 2em;
        margin-bottom: 1em;
      }

      .dev-team {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        a {
          margin: 0.25em;
        }
      }

      .btn-sponsor {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .kromac-sponsor-btn {
          height: 100px;
          text-align: center;
          width: 100px;

          label {
            text-align: center;
          }

          form {
            border: solid;
            border-radius: 50%;
            box-shadow: 2px 2px 10px 0px #919191, inset 2px 2px 10px 0px #919191;
            height: 100px;
            width: 100px;

            img,
            input[type="image"] {
              height: 100%;
              width: 100%;
            }

            img {
              display: none;
            }
          }
        }
      }

      .kromac-subsection {
        margin-top: 1em;
        padding-left: 0;

        .tech {
          background: #17202a;
          display: flex;
          gap: 20px;
          max-width: 2880px;
          overflow: hidden;

          li {
            width: 100%;

            a {
              align-items: center;
              display: flex;
              height: 150px;
              left: calc(var(--left) * -320px);
              margin: 0;
              position: relative;
              transition: 2s ease;
              width: 300px;

              img {
                cursor: pointer;
                height: 100px;
                object-fit: contain;
                width: 100%;
              }
            }
          }
        }
      }

      .comingsoon {
        align-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .card-hexagon {
          .kromac-card {
            height: 200px;
            max-width: 200px;
            min-width: 200px;
          }
        }
      }

      .kromac-panel {
        height: auto;
        max-height: 100%;
      }
      p {
        margin-bottom: 0;
      }
      .label-import {
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  ion-icon {
    color: #fff;
    font-size: 2em;
  }
}

@media (max-width: 904px) {
  .comingsoon {
    justify-content: center;

    .kromac-container:nth-last-child(1) {
      margin-top: -70px;
    }
  }
}

@media (max-width: 768px) {
  .comingsoon {
    .kromac-container:nth-last-child(1) {
      margin-top: 0;
    }
  }
}

@media (max-width: 678px) {
  .comingsoon {
    .kromac-container:nth-last-child(1) {
      margin-top: -70px;
    }
  }
}

@media (max-width: 469px) {
  .comingsoon {
    .kromac-container:nth-last-child(1) {
      margin-top: 0;
    }
  }
}
