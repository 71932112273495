.kromac-spinner.spinner-lighter {
  .loader {
    animation: rotate 1s infinite linear;
    background: #0d2323;
    border-radius: 50%;
    height: 100%;
    width: 100%;

    &::before {
      background: linear-gradient(to top, transparent, #00fff9);
      background-repeat: no-repeat;
      background-size: 100px 180px;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
      box-shadow: none !important;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 50%;
    }

    &::after {
      background: #00fff9;
      border: solid 1px #04cdca;
      border-radius: 50%;
      box-shadow: 0 0 10px #00fff9, 0 0 20px #00fff9, 0 0 30px #00fff9,
        0 0 40px #00fff9, 0 0 50px #00fff9, 0 0 60px #00fff9, 0 0 70px #00fff9,
        0 0 80px #00fff9, 0 0 90px #00fff9, 0 0 100px #00fff9;
      content: "";
      height: 20px;
      left: 45%;
      position: absolute;
      top: 0;
      transform: scale(1.5);
      width: 20px;
      z-index: 10;
    }

    span {
      border-radius: 50%;
      bottom: 20px;
      box-shadow: inset 0 0 15px 5px rgba(0, 0, 0, 0.5);
      left: 20px;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
}
