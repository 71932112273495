.kromac-spinner.spinner-rainbown {
  border: solid 4px #f1f1f1;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 1),
    10px 10px 10px rgba(0, 0, 0, 0.1),
    inset -10px -10px 15px rgba(255, 255, 255, 1),
    inset 10px 10px 10px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden;

  &::before {
    background: var(--bgSpiner);
    border: solid 4px #f1f1f1;
    border-radius: 50%;
    box-shadow: inset -10px -10px 15px rgba(255, 255, 255, 1),
      inset 10px 10px 10px rgba(0, 0, 0, 0.1) !important;
    content: "";
    height: 150px;
    position: absolute;
    width: 150px;
  }

  .loader {
    height: 100%;
    width: 100%;

    span {
      animation: rotate 1s infinite linear;
      background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
      border-radius: 50%;
      filter: blur(20px);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      &::before {
        animation: spinnerSVG 2s infinite linear;
        animation-delay: calc(0.1s * var(--i) * -1);
        background: var(--loaderColor);
        border-radius: 50%;
        content: "";
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
        width: 15px;
      }
    }
  }
}
