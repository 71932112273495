$textShadow: 1px 1px 2px #000, 0 0 0.25em #000, 0 0 0.2em #000;

.kromac-panel {
  background: #fff;
  border-radius: 0.5em;
  height: auto;
  padding: 1em;
  position: relative;
  width: 100%;

  &.shadows {
    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
  }

  .content {
    position: relative;
    z-index: 100;
  }

  &.transparent {
    backdrop-filter: blur(20px);
    background: transparent;
    border: solid 2px #0a0a0a;

    .content {
      color: #fff;
      text-shadow: $textShadow;
    }
  }

  &.neon {
    border: none;
    position: relative;

    .content {
      color: #fff;
    }

    span {
      background: #212f3c;
      border-radius: 0.5em;
      bottom: 4px;
      content: "";
      display: block;
      height: auto;
      left: 4px;
      position: absolute;
      right: 4px;
      top: 4px;
      width: auto;
    }

    &::before {
      animation: animate 20s linear infinite;
      background: linear-gradient(
        45deg,
        #fb0094,
        #00f,
        #0f0,
        #ff0,
        #f00,
        #0f0,
        #ff0,
        #f00
      );
      background-size: 400%;
      border-radius: 0.5em;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 1s ease;
      width: 100%;
    }

    @keyframes animate {
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 300% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  }
}
