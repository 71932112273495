.kromac-footer-container {
  border-top: solid 1px #566573;
  bottom: 0;
  height: 50px;
  position: absolute;
  width: -webkit-fill-available;

  .kromac-footer {
    align-items: center;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 100%;

    a,
    a:hover {
      text-decoration: none;
    }
  }
}
