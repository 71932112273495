$textShadow-bg-white: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;

.kromac-container.card-percentage {
  .kromac-card {
    border-radius: 0.25em;
    height: 300px;
    margin: 10px 0;
    max-width: 300px;

    &::before {
      background: rgba(255, 255, 255, 0.1);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 50%;
    }

    .kromac-card-caption {
      align-content: center;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 35px;
      padding: 1em 2em;

      .kromac-card-content {
        .percent {
          border-radius: 50%;
          box-shadow: inset 0 0 50px #000;
          margin-bottom: 2em;
          width: 150px;
          z-index: 100;
        }

        h2,
        .card-title {
          text-align: center;
          width: 100%;
        }

        h2 {
          left: 0;
          margin: 0;
          position: absolute;
          top: 75px;
        }

        svg {
          height: 150px;
          position: relative;
          width: 150px;
          z-index: 10000;

          circle {
            fill: none;
            height: 100%;
            stroke: #191919;
            stroke-linecap: round;
            stroke-width: 10;
            transform: translate(5px, 5px);
            width: 100%;

            &:nth-child(2) {
              stroke-dasharray: 440;
              stroke-dashoffset: 440;
            }
          }
        }
      }
    }
  }
}
