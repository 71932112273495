$textShadow-bg-white:
  1px 1px 2px black,
  0 0 1em black,
  0 0 0.2em black;
$textShadow-bg-black:
  1px 1px 2px black,
  0 0 0.25em white,
  0 0 0.2em black;

* {
  box-sizing: border-box;
  line-height: 1.5;
  margin: 0;
  padding: 0;

  p {
    font-weight: 400;
  }
}

.kromac-logo-storybook {
  width: 200px;
  height: 200px;
  margin: auto;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.kromac-container-main .kromac-row-main {
  margin-bottom: 0;
  min-height: 100vh;
}

.dark {
  background-color: #212f3d;
  color: #fff;

  & > .kromac-footer {
    background-color: #212f3d;
  }

  tr,
  th {
    color: #fff;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

h6 {
  font-size: 1em;
}

.label-import {
  background: #566573;
  border-radius: 0.25em;
  color: #fff;
  padding: 2px 10px;
}

label {
  font-size: 12px;
}

.primary {
  background: #2e86c1;
  color: #fff;
}

.success {
  background: #28b463;
  color: #fff;
}

.warning {
  background: #f1c40f;
  color: #000;
}

.error {
  background: #cb4335;
  color: #fff;
}

.danger {
  background: #d68910;
  color: #fff;
}

.info {
  background: #d5dbdb;
  color: #000;
}

.night {
  background: #212f3d;
  color: #fff;
}

.text-bg-dark {
  color: #fff;
  text-shadow: $textShadow-bg-black !important;
}

.text-bg-light {
  color: #fff;
  text-shadow: $textShadow-bg-white !important;
}

button.glass {
  backdrop-filter: blur(20px);
  background: transparent;
  border: solid 1px #fff;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  padding: 0.5em 1em;
  text-shadow: $textShadow-bg-white;
}

.div-implementation {
  background: #17202a;
  border-radius: 0.5em;
  border-top: solid 1.25em gray;
  color: #fff;
  height: 350px;
  margin: 0.75em;
  padding: 1em;
  position: relative;

  code {
    .implementation {
      height: 100%;
      overflow: auto;
    }

    span {
      position: inherit;
    }

    .var {
      color: #2874a6;
    }

    .function {
      color: #f7dc6f;
    }

    .react {
      color: #52be80;
    }

    .comment {
      color: green;
    }

    .component {
      color: #3498db;
    }

    .string {
      color: orange;
    }

    .text {
      color: #fff;
    }

    .tab {
      padding-left: 1em;
    }

    .tab2 {
      padding-left: 2em;
    }

    .tab3 {
      padding-left: 3em;
    }

    .tab4 {
      padding-left: 4em;
    }

    .tab5 {
      padding-left: 5em;
    }
  }

  span {
    border-radius: 50%;
    height: 10px;
    left: 10px;
    position: absolute;
    top: -14px;
    width: 10px;

    &.yellow {
      left: 25px;
    }

    &.green {
      left: 40px;
    }
  }

  textarea,
  textarea:focus,
  textarea:active {
    border: none;
    color: #fff;
    font-family: monospace;
    height: 100%;
    outline: none;
    resize: none;
    width: 100%;
  }

  @media (max-width: 991px) {
    margin: 0;
  }
}

.kromac-version-select {
  background: transparent;
  border: none;
  border-bottom: solid 1px #fff;
  color: #fff;
  cursor: pointer;
  display: block;
  height: auto;
  margin-bottom: 5px;
  outline: none;
  pointer-events: initial;
  text-shadow: 1px 1px 2px #000;
  width: fit-content;
  z-index: 2;

  option {
    color: #000;
    cursor: pointer;
  }
}

.kromac-title {
  color: #fff;
  padding: 1em;
  position: sticky;
  text-align: center;
  top: 0;
  z-index: 100;

  .kromac-input-search {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding-top: 24px;
    position: sticky;
    top: 119px;

    label {
      color: #fff;
      position: absolute;
      top: 0;
    }
  }
}

.h-menu-kromac,
.kromac-title-landingpage {
  display: none;
}

.storybook-link-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  gap: 10px;
  font-size: 1em;
  color: inherit;

  p,
  h3 {
    margin: 0;
  }

  img {
    height: 2em;
    width: fit-content;
  }
}

@media (max-width: 767px) {
  .kromac-components {
    display: none;
  }

  .h-menu-kromac,
  .kromac-title-landingpage {
    align-items: center;
    display: flex;
    flex-direction: column;

    .kromac-version-select {
      border-bottom: solid 1px #000;
      pointer-events: visible;
      text-shadow: $textShadow-bg-black;
    }
  }
}

.kromac-scroll {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &:-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
.kromac-scroll-bg-dark {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &:-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
