.kromac-spinner {
  align-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  height: 200px;
  justify-content: center;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 200px;

  .loader {
    height: 120px;
    position: relative;
    width: 120px;
  }

  &.sm {
    transform: translateX(-50%) scale(0.5);
  }

  &.md {
    transform: translateX(-50%) scale(0.75);
  }

  &.shadows {
    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5),
      inset 0 0 12px 0 rgba(255, 255, 255, 0.5);

    &::before {
      box-shadow: inset 2px 2px 5px rgba($color: #000000, $alpha: 0.5),
        0 0 12px 0 rgba(255, 255, 255, 0.5);
    }
  }
}

@keyframes rotate {
  0% {
    filter: hue-rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinnerWaves {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
