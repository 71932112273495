.kromac-banner-image {
  background-size: cover;
  margin-bottom: 50px;

  .kromac-banner-caption {
    align-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}
