.gallery-default {
  display: grid;
  grid-auto-flow: dense;
  margin-bottom: 2em;
  width: 100%;

  .bgblur {
    backdrop-filter: blur(5px) brightness(0.2);
    height: 100%;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 0;

    &.active {
      width: 100%;
      z-index: 1;
    }
  }

  .kromac-box {
    overflow: hidden;
    position: relative;

    .kromac-box-container {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      &:hover > .brillo {
        animation: brillo 1s ease-out;
        z-index: 1;
      }

      &:hover > img,
      &:hover > video {
        transform: scale(1.2);
        transition: all 1s ease;
      }

      img,
      video,
      iframe {
        cursor: pointer;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        transition: all 1s ease;
        width: 100%;
      }

      .close {
        background: #000;
        border: none;
        border-radius: 50%;
        box-shadow: none;
        color: #fff;
        display: none;
        font-size: 3em;
        height: 50px;
        pointer-events: visible;
        position: absolute;
        right: 5px;
        top: 35px;
        width: 50px;
        z-index: 10000000;
      }
    }

    &.active {
      grid-column: none !important;
      grid-row: none !important;
      height: 90%;
      left: 50%;
      margin: 0;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      z-index: 1001;

      .brillo {
        width: 0;
      }

      p {
        display: none;
      }

      .kromac-box-container {
        &:hover > img {
          transform: scale(1);
        }

        img,
        video,
        iframe {
          height: 100%;
          object-fit: contain;
          position: inherit;
          z-index: 1001;
        }

        .close {
          display: flex;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .gallery-default {
    display: flex;
    flex-wrap: wrap;

    .kromac-box {
      height: 300px;
      width: 100%;
    }
  }
}
