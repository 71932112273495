$textShadow-bg-white: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;

.kromac-container.card-user {
  .kromac-card.card-user-info {
    border-radius: 0.5em;
    height: 400px;
    margin: 10px 0;
    max-width: 300px;
    overflow: hidden;

    .kromac-card-image {
      height: 400px;
      transform-origin: top;
      transition: var(--transition);
      width: 100%;
      z-index: 1;

      img,
      video,
      iframe {
        border-radius: 0.5em;
      }
    }

    &:hover > .kromac-card-image {
      transform: translateY(30px) scale(0.5);
    }

    &:hover > .kromac-card-caption {
      transform: translateY(0);
    }

    .kromac-card-caption {
      bottom: 40px;
      position: absolute;
      text-align: center;
      transform: translateY(100%);
      transition: var(--transition);
      width: 100%;

      .card-title {
        text-shadow: $textShadow-bg-white;

        h4 {
          color: #fff;
        }

        label {
          color: #3498db;
          font-size: 0.85em;
        }
      }

      .card-text {
        display: none;
      }

      ul {
        align-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0.5em;

          a,
          a:hover {
            align-content: center;
            align-items: center;
            color: #fff;
            display: flex;
            flex-wrap: wrap;
            height: 25px;
            justify-content: center;
            padding: 0.5em;
            text-decoration: none;
            transition: all var(--transition) ease;
            width: 25px;

            &:hover {
              transform: rotate(360deg);
            }

            i {
              img {
                height: 35px;
                width: 35px;
              }
            }
          }
        }
      }
    }
  }

  .kromac-card.card-user-summary {
    border-radius: 0.5em;
    color: #fff;
    height: 400px;
    margin: 10px 0;
    max-width: 300px;
    overflow: hidden;
    text-shadow: $textShadow-bg-white;
    transition: all var(--transition) linear;

    .kromac-card-image {
      align-content: center;
      align-items: center;
      border-radius: 50%;
      bottom: 115px;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;

      img,
      video,
      iframe {
        border: solid 3px #fff;
        border-radius: 50%;
      }
    }

    .kromac-card-caption {
      width: 100%;

      .card-title {
        bottom: 50px;
        position: absolute;
        text-align: center;
        width: 100%;

        label {
          color: #3498db;
          font-size: 0.85em;
        }
      }

      .card-text {
        align-content: stretch;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        height: 170px;
        overflow: auto;
        padding: 1em;
        text-align: center;
        width: 100%;

        @media (max-width: 1154px) {
          & {
            height: 160px;
          }
        }
      }
    }

    &:hover {
      background: linear-gradient(45deg, #ec39d6 0%, #2ecff3 100%) !important;
      transition: all var(--transition);
    }
  }
}
