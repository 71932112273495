#menuPreview {
  .kromac-hamburger-menu {
    display: flex;
  }
  .component-implementation {
    display: none;
  }
}

.box {
  position: fixed;
  width: 100%;
  z-index: 1000;

  label {
    backdrop-filter: blur(20px);
    border: solid 1px #212f3d;
    border-radius: 0.25em;
    padding: 0.25em;
    position: absolute;
    right: 90px;
    top: -160px;
    width: 100px;
  }

  img {
    height: 70px;
    position: absolute;
    right: 55px;
    top: -120px;
    transform: rotate(172deg);
    width: 100px;
  }
}

@media (max-width: 767px) {
  #menuPreview {
    .kromac-hamburger-menu {
      display: none;
    }
  }
}
