.kromac-chip {
  border-radius: 16px;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0 12px;
}
