.kromac-float-btn {
  position: fixed;
  right: 15px;
  top: 50%;
  z-index: 10;

  button,
  .btn-setting {
    align-items: center;
    background: #212f3d;
    border: none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    opacity: 1;
    width: 50px;
  }

  .kromac-float-btn-options {
    button {
      background: linear-gradient(
        0deg,
        rgb(166, 69, 163) 0%,
        rgb(77, 202, 208) 100%,
        rgb(255, 250, 250) 100%
      );
      position: absolute;
      top: 0;
      transition: ease-in-out 0.5s all;
    }
  }

  .btn-setting {
    border: solid 1px #fff;
    padding: 0.75em;
    position: relative;
    transition: ease-in-out 0.5s all;
    z-index: 11;

    &:hover,
    &.open {
      background: #fff;
      border: solid 1px;
      color: #000;
      opacity: 1 !important;
    }

    &.open {
      padding: 1em;
      transform: rotate(135deg);
    }

    &.open ~ .kromac-float-btn-options {
      button:nth-child(1) {
        top: -60px;
      }

      // button:nth-last-child(1) {
      //   top: 60px;
      // }
    }
  }
}
