.kromac-menu-hamburger {
  background: var(--bgColor);
  bottom: 0;
  box-sizing: initial;
  display: block !important;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: width var(--transition);
  width: 0px;
  z-index: 10000;

  .kromac-title {
    background: var(--bgColor);
    color: #fff;
    height: auto;
    padding: 1em;
    position: sticky;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 10000;

    a {
      color: #fff;
      text-decoration: none;
    }

    img {
      height: 100px;
      width: auto;
    }
  }

  .kromac-input-search {
    background: var(--bgColor);
    margin: 0;
    margin-top: 10px;
    top: auto;
    z-index: 10000;

    input,
    input:focus,
    input:active {
      background: var(--bgColor);
      background: #fff;
      border: none;
      border-radius: 0.25em;
      box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
      height: 24px;
      outline: none;
      padding: 5px 10px;
      width: 80%;
      z-index: 10000;
    }
  }

  &.active {
    border-left: solid 5px var(--bgColor);
    width: 300px;
  }

  ul {
    border: none;
    left: 0;
    padding-left: 5px;
    top: 0;
    width: 100%;

    .kromac-menu-list {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      list-style: none;
      position: relative;
      width: 100%;

      b:nth-child(1) {
        background: #fff;
        display: none;
        height: 20px;
        position: absolute;
        top: -20px;
        width: 100%;

        &::before {
          background: var(--bgColor);
          border-bottom-right-radius: 20px;
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      b:nth-child(2) {
        background: #fff;
        bottom: -20px;
        display: none;
        height: 20px;
        position: absolute;
        width: 100%;

        &::before {
          background: var(--bgColor);
          border-top-right-radius: 20px;
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      a {
        display: block;
        display: flex;
        position: relative;
        text-decoration: none;
        width: 100%;

        .icon {
          display: block;
          height: 60px;
          line-height: 600;
          min-width: 60px;
          position: relative;
          text-align: center;

          ion-icon {
            font-size: 1.5em;
          }
        }

        .kromac-menu-title {
          display: block;
          line-height: 60px;
          padding-left: 10px;
          position: relative;
          text-transform: capitalize;
          white-space: normal;
        }
      }

      &.active {
        background: #fff;
        a {
          color: #000;
        }
        b:nth-child(1),
        b:nth-child(2) {
          display: block;
        }
      }
    }
  }

  .kromac-hamburger-menu {
    align-items: center;
    background: var(--hamburgerColor);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    position: fixed;
    right: 20px;
    top: 20px;
    width: 50px;
    z-index: 100;

    img {
      display: none;
      height: 34px;
      position: absolute;
      width: 34px;

      &.open {
        display: block;
      }
    }

    &.active {
      background: var(--hamburgerActiveColor);

      img {
        &.close {
          display: block;
          mix-blend-mode: screen;
        }
        &.open {
          display: none;
        }
      }
    }
  }

  &.kromac-scroll {
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
    &:-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
}
