$textShadow-bg-black:
  1px 1px 2px black,
  0 0 0.25em white,
  0 0 0.2em black;

.right {
  float: none !important;
}

.left {
  float: left !important;
}

.kromac-container.horizontal {
  width: 100%;
  max-width: 600px;

  .kromac-card {
    margin: 10px 0;
    width: 100%;
    max-width: 600px;

    &.transparent.left {
      backdrop-filter: blur(20px);
    }

    .kromac-card-image {
      height: 300px;
      position: relative;
      width: 50%;
      z-index: 10;

      .card-title {
        z-index: 100;

        h4 {
          text-shadow: $textShadow-bg-black;
        }
      }

      &.right {
        position: absolute;
        right: 0;
      }
    }

    .kromac-card-caption {
      background: #fff;
      transition: all var(--transition) ease-out;
      width: 50%;

      .card-text {
        height: 300px;
        overflow: auto;

        p {
          margin-bottom: 0;
        }
      }

      &.transparent {
        text-shadow:
          1px 1px 2px black,
          0 0 0.25em white,
          0 0 0.2em black;
      }

      &.left {
        display: contents;
        transform: translateX(-100%);

        &.expanded {
          display: block;
          transform: translateX(-100%);
        }
      }

      &.right {
        &.transparent {
          transform: translateX(0%);
        }

        &.expanded {
          transform: translateX(50%);
        }
      }
    }

    &.horizontal.expanded {
      display: contents;

      &.right {
        display: block;
        margin: 0;

        .kromac-card-caption {
          transform: translateX(100%);
        }
      }
    }
  }

  &.expanded {
    margin: 10px 0;
    position: inherit;
    transition: all var(--transition) ease-out;
  }
}
